import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    NewCoursesConstants,
    NewCoursesState
} from './new-courses.types';
import { RootState } from '@config/react-redux/store';
import { SetCourseExpandCollapse } from '@containers/activity-router/data-grid-container/courses/types/courses.types';
import {
    MultiSelectOption
} from '@containers/activity-router/data-grid-container/generic-grid-filters/multi-select/multi-select.types';
import {
    manageCourseStrandIsExpanded,
    manageCourseTopicExpandCollapse,
    manageCourseTopicExpandCollapseCheck
} from '@containers/activity-router/routes/helpers/courses/course-expand-helpers';
import { resetGridCells } from '@containers/activity-router/routes/helpers/grid/cells/grid-cells-helpers';
import {
    resetGridCellCounters
} from '@containers/activity-router/routes/helpers/grid/counters/grid-cell-counters/grid-cell-counters';
import {
    RouteHelpersModalConstants
} from '@containers/activity-router/routes/helpers/modal/route-modal-helpers.types';
import { toggleGridCells } from '@containers/activity-router/routes/helpers/slice/grid/slice-grid-helpers';
import { defaultState } from '@containers/activity-router/routes/helpers/slice/slice-defaults';
import {
    toggleRouteActivityGridCheckbox,
    toggleRouteStudentGridCheckbox,
    toggleRouteTopicGridCheckbox
} from '@containers/activity-router/routes/helpers/slice/slice-helpers';
import {
    GridCells,
    GridCounters,
    ToggleActivityGridCheckbox,
    ToggleGridCell,
    ToggleStudentGridCheckbox,
    ToggleTopicGridCheckbox
} from '@containers/activity-router/routes/helpers/slice/topic/topic-slice.types';
import {
    manageNewCoursesMultiSelect
} from '@containers/activity-router/routes/learn/new-courses/components/new-courses-multi-select/new-courses-multi-select-helpers';
import {
    manageNewCoursesCellAssignmentsSelected,
    manageNewCoursesSelectedAssignments,
    updateNewCoursesAssignments
} from '@containers/activity-router/routes/learn/new-courses/helpers/slice/assignments/selected/assignments-selected-helpers';
import {
    TransformCourse,
    TransformedNewCoursesAssignments
} from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

const { name } = NewCoursesConstants;
const initialState: NewCoursesState = {
    ...defaultState,
    assignments: {
        ...defaultState.assignments,
        grid: {}
    },
    course: {
        selected: null,
        filtered: null
    },
    modal: null,
    filters: {
        isExpanded: true
    }
};

export const newCoursesSlice = createSlice({
    name,
    initialState,
    reducers: {
        setNewCoursesCourseSelected (state, action: PayloadAction<TransformCourse>) {
            state.course.selected = action.payload;
        },
        setNewCoursesGridCountersInitialValue (state, action: PayloadAction<GridCounters>) {
            state.grid.counters = action.payload;
        },
        setNewCoursesGridCells (state, action: PayloadAction<GridCells>) {
            state.grid.cells = action.payload;
        },
        setNewCoursesModal (state, action: PayloadAction<RouteHelpersModalConstants | null>) {
            state.modal = action.payload;
        },
        resetNewCoursesCourseSelected (state) {
            state.course.selected = null;
        },
        resetNewCoursesGrid (state) {
            state.grid.cells = resetGridCells({
                gridCells: state.grid.cells,
                isSelected: false
            });
            state.grid.counters = resetGridCellCounters({
                counters: state.grid.counters
            });
        },
        resetNewCoursesSelectedAssignments (state) {
            state.assignments.selected = defaultState.assignments.selected;
        },
        setNewCoursesCourseIsExpanded (state, action: PayloadAction<SetCourseExpandCollapse>) {
            if (state.course.selected) {
                state.course.selected.topics = manageCourseStrandIsExpanded({
                    course: state.course.selected,
                    payload: action.payload
                });

                if (manageCourseTopicExpandCollapseCheck({
                    course: state.course.selected,
                    isExpanded: state.filters.isExpanded
                })) {
                    state.filters.isExpanded = !state.filters.isExpanded;
                }
            }
        },
        toggleNewCoursesGridCells (state, action: PayloadAction<ToggleGridCell>) {
            const {
                student,
                ...payload
            } = action.payload;
            const {
                gridCells,
                gridCounters
            } = toggleGridCells({
                gridCells: state.grid.cells,
                gridCounters: state.grid.counters,
                studentId: student.userProfileId.toString(),
                ...payload
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
            state.assignments.selected = manageNewCoursesCellAssignmentsSelected({
                ...action.payload,
                studentAssignments: state.assignments.grid,
                gridCells: state.grid.cells,
                selectedAssignments: state.assignments.selected
            });
        },
        toggleNewCoursesStudentGridCheckbox (state, action: PayloadAction<ToggleStudentGridCheckbox>) {
            const {
                gridCells,
                gridCounters,
                assignmentsSelected
            } = toggleRouteStudentGridCheckbox({
                payload: action.payload,
                state: {
                    gridCells: state.grid.cells,
                    gridCounters: state.grid.counters,
                    assignmentsSelected: state.assignments.selected
                },
                manageSelectedAssignments: (params) => manageNewCoursesSelectedAssignments({
                    ...params,
                    studentAssignments: state.assignments.grid
                })
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
            state.assignments.selected = assignmentsSelected;
        },
        toggleNewCoursesTopicGridCheckbox (state, action: PayloadAction<ToggleTopicGridCheckbox>) {
            const {
                gridCells,
                gridCounters,
                assignmentsSelected
            } = toggleRouteTopicGridCheckbox({
                payload: action.payload,
                state: {
                    gridCells: state.grid.cells,
                    gridCounters: state.grid.counters,
                    assignmentsSelected: state.assignments.selected
                },
                manageSelectedAssignments: (params) => manageNewCoursesSelectedAssignments({
                    ...params,
                    studentAssignments: state.assignments.grid
                })
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
            state.assignments.selected = assignmentsSelected;
        },
        toggleNewCoursesActivityGridCheckbox (state, action: PayloadAction<ToggleActivityGridCheckbox>) {
            const {
                gridCells,
                gridCounters,
                assignmentsSelected
            } = toggleRouteActivityGridCheckbox({
                payload: action.payload,
                state: {
                    gridCells: state.grid.cells,
                    gridCounters: state.grid.counters,
                    assignmentsSelected: state.assignments.selected
                },
                manageSelectedAssignments: (params) => manageNewCoursesSelectedAssignments({
                    ...params,
                    studentAssignments: state.assignments.grid
                })
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
            state.assignments.selected = assignmentsSelected;
        },
        setNewCoursesAssignmentGrid (state, action: PayloadAction<TransformedNewCoursesAssignments>) {
            state.assignments.grid = action.payload;
        },
        resetNewCoursesAssignments (state) {
            state.assignments = {
                grid: {},
                selected: defaultState.assignments.selected
            };
            state.filters.isExpanded = true;
        },
        setNewCoursesMultiSelect (state, action: PayloadAction<{ option: MultiSelectOption }>) {
            const {
                gridCells,
                gridCounters
            } = manageNewCoursesMultiSelect({
                option: action.payload.option,
                gridCells: state.grid.cells,
                gridCounters: state.grid.counters,
                studentAssignments: state.assignments.grid,
                selectedAssignments: state.assignments.selected
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
        },
        updateNewCoursesAssignment (state, action: PayloadAction<TransformedNewCoursesAssignments>) {
            state.assignments.selected = updateNewCoursesAssignments({
                updatedStudentAssignments: action.payload,
                selectedAssignments: state.assignments.selected,
                gridCells: state.grid.cells
            });
        },
        setNewCoursesIsExpanded (state, action: PayloadAction<boolean>) {
            state.filters.isExpanded = action.payload;
            if (state.course.selected) {
                state.course.selected = manageCourseTopicExpandCollapse({
                    course: state.course.selected,
                    isExpanded: action.payload
                });
                if (state.course.filtered) {
                    state.course.filtered = manageCourseTopicExpandCollapse({
                        course: state.course.filtered,
                        isExpanded: action.payload
                    });
                }
            }
        }
    }
});

/**
 * ACTIONS
 */
export const {
    setNewCoursesGridCells,
    setNewCoursesCourseSelected,
    setNewCoursesGridCountersInitialValue,
    setNewCoursesModal,
    resetNewCoursesGrid,
    resetNewCoursesSelectedAssignments,
    resetNewCoursesCourseSelected,
    setNewCoursesCourseIsExpanded,
    toggleNewCoursesGridCells,
    toggleNewCoursesStudentGridCheckbox,
    toggleNewCoursesTopicGridCheckbox,
    toggleNewCoursesActivityGridCheckbox,
    setNewCoursesAssignmentGrid,
    setNewCoursesMultiSelect,
    resetNewCoursesAssignments,
    updateNewCoursesAssignment,
    setNewCoursesIsExpanded
} = newCoursesSlice.actions;

/**
 * SELECTORS
 */
export const selectNewCoursesCourseSelected = (state: RootState) => state[name].course.selected;

export const selectNewCoursesGridCounters = (state: RootState) => state[name].grid.counters;

export const selectNewCoursesGridCells = (state: RootState) => state[name].grid.cells;

export const selectNewCoursesAssignmentsSelected = (state: RootState) => state[name].assignments.selected;

export const selectNewCoursesAssignmentsGrid = (state: RootState) => state[name].assignments.grid;

export const selectNewCoursesModal = (state: RootState) => state[name].modal;

export const selectNewCoursesIsExpanded = (state: RootState) => state[name].filters.isExpanded;

export default newCoursesSlice.reducer;
