import { ProgressProps } from '@chakra-ui/react';
import { AnyAction } from '@reduxjs/toolkit';

import { GridCounts } from '../../routes/helpers/slice/slice.types';
import { StudentCount } from '../../routes/helpers/slice/topic/topic-slice.types';
import { GridScrollRef } from '../data-grid-container.types';
import {
    AssignmentModalActivitiesProps,
    ViewAssignmentClickProps
} from './components/view-assigned-activities-modal/view-assigned-activities-modal.types';
import {
    CombinedAssignSelectedGroupStudent,
    SelectedGroupStudents
} from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

export type StudentListSortKey= keyof Pick<CombinedAssignSelectedGroupStudent, 'firstName' | 'lastName'>;

export enum StudentListConstants {
    name = 'studentList'
}

export type ClassroomId = number | null;

export type GroupId = number | null;

export type UserProfileId = number;

export type TopicId = string;

export type ClassroomGroupId = {
    classroomId: ClassroomId
    groupId: GroupId
};

export type StudentListSelected = ClassroomGroupId & { selectedGroupStudents: SelectedGroupStudents };

export type StudentListState = {
    selected: StudentListSelected
    sortKey: StudentListSortKey
};

export type OnStudentCheckboxClickHandlerParams = {
    student: CombinedAssignSelectedGroupStudent
    isChecked: boolean
};

export type OnStudentCheckboxClickHandler = ({
    student,
    isChecked
}: OnStudentCheckboxClickHandlerParams) => AnyAction;

export type StudentDetailsProps = Pick<StudentListProps, 'sortKey' | 'onStudentCheckboxClickHandler'> & {
    studentCountValue: GridCounts
    student: CombinedAssignSelectedGroupStudent
    selectedAssignment: GetSelectedAssignmentsReturn
    viewAssignmentClick: (props: StudentListViewAssignmentClickProps) => void
    isLastStudent: boolean
};

export type StudentListProps = {
    isLoading: boolean
    getSelectedAssignment: GetSelectedAssignment
    selectedGroupStudents: SelectedGroupStudents
    sortKey: StudentListSortKey
    onStudentCheckboxClickHandler: OnStudentCheckboxClickHandler
    studentCount: StudentCount
    gridScrollRef?: GridScrollRef
    assignedActivitiesModalProps: AssignmentModalActivitiesProps
};

export type SortStudentListPayload = {
    sortKey: StudentListSortKey
};

export type StudentItemProps = Pick<StudentDetailsProps, 'sortKey' | 'student' | 'isLastStudent'> & {
    isActive: boolean
    handleClick: () => void
};

export type SortMenuItemProps = {
    isSelected: boolean
    clickHandler: () => void
    label: string
};

export type SortStudentByKeyParams = {
    students: CombinedAssignSelectedGroupStudent[]
    key: keyof Omit<CombinedAssignSelectedGroupStudent, 'groupId'>
};

export type StudentListData = StudentListSelected | null;

export type SetStudentListSelectedStudents = ClassroomGroupId & {
    selectedGroupStudents: SelectedGroupStudents
};

export type StudentPopoverProps = {
    selectedAssignment: GetSelectedAssignmentsReturn
    studentDetails: CombinedAssignSelectedGroupStudent
    isActive: boolean
    setIsActive: (isActive: boolean) => void
    viewAssignmentClick: (props: StudentListViewAssignmentClickProps) => void
    isHovered: boolean
};

export type StudentListViewAssignmentClickProps = Pick<ViewAssignmentClickProps, 'userProfileId' | 'groupId' | 'encryptedUserProfileId'>;

export type GetSelectedAssignmentsReturn = {
    percentage: number
    assignedActivityTotal: number
    completedActivityTotal: number
    nextDueDate: string | null
    averageResultScore: number | null
    overdueActivityTotal: number
    totalAssignments: number
    helpers: {
        averageAccuracyProgressStyle: ProgressProps
        dueDate: string
    }
};

export type StudentListPercentageProps = Pick<GetSelectedAssignmentsReturn, 'assignedActivityTotal' | 'completedActivityTotal'>;

export type GetSelectedAssignment = (userProfileId: UserProfileId) => GetSelectedAssignmentsReturn;
