import { CourseData } from '@containers/activity-router/data-grid-container/courses/types/courses.types';
import {
    RouteHelpersModalConstants
} from '@containers/activity-router/routes/helpers/modal/route-modal-helpers.types';
import { DefaultState } from '@containers/activity-router/routes/helpers/slice/slice.types';
import {
    TransformedNewCoursesAssignments
} from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

export enum NewCoursesConstants {
    name = 'newCourses'
}

export type NewCoursesState = DefaultState & {
	course: {
		selected: DefaultState['course']['selected']
		filtered: CourseData
	}
    assignments: {
        grid: TransformedNewCoursesAssignments
    }
    modal: RouteHelpersModalConstants | null
	filters: {
		isExpanded: boolean
	}
};
