import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SetCourseExpandCollapse } from '../../../data-grid-container/courses/types/courses.types';
import { manageCourseStrandIsExpanded } from '../../helpers/courses/course-expand-helpers';
import {
    GridCells,
    GridCounters,
    ToggleActivityGridCheckbox,
    ToggleGridCell, ToggleStudentGridCheckbox,
    ToggleTopicGridCheckbox
} from '../../helpers/slice/topic/topic-slice.types';
import {
    SkillQuestsConstants,
    SkillQuestsState
} from './skill-quests.types';
import { RootState } from '@config/react-redux/store';
import { RouteHelpersModalConstants } from '@containers/activity-router/routes/helpers/modal/route-modal-helpers.types';
import {
    toggleActivityGridCheckbox,
    toggleStudentGridCheckbox,
    toggleTopicGridCheckbox
} from '@containers/activity-router/routes/helpers/slice/checkbox/slice-grid-checkbox-helpers';
import { toggleGridCells } from '@containers/activity-router/routes/helpers/slice/grid/slice-grid-helpers';
import { defaultState } from '@containers/activity-router/routes/helpers/slice/slice-defaults';
import { GridCount } from '@containers/activity-router/routes/helpers/slice/slice.types';
import {
    TransformCourse
} from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

const { name } = SkillQuestsConstants;
const {
    studentCount,
    topicCount,
    topicActivityCount,
    activityCount
} = GridCount;
const initialState: SkillQuestsState = {
    course: {
        selected: null
    },
    grid: {
        cells: {},
        counters: {
            [studentCount]: {},
            [topicCount]: {},
            [activityCount]: {},
            [topicActivityCount]: {}
        }
    },
    assignments: {
        ...defaultState.assignments,
        grid: {}
    },
    modal: null
};

export const skillQuestsSlice = createSlice({
    name,
    initialState,
    reducers: {
        setSkillQuestsCourseSelected (state, action: PayloadAction<TransformCourse>) {
            state.course.selected = action.payload;
        },
        setSkillQuestsCourseStrandIsExpanded (state, action: PayloadAction<SetCourseExpandCollapse>) {
            if (!state.course.selected) return;

            state.course.selected.topics = manageCourseStrandIsExpanded({
                course: state.course.selected,
                payload: action.payload
            });
        },
        setSkillQuestsCourseIsExpanded (state, action: PayloadAction<SetCourseExpandCollapse>) {
            if (!state.course.selected) return;

            state.course.selected.topics = manageCourseStrandIsExpanded({
                course: state.course.selected,
                payload: action.payload
            });
        },
        setChallengesGridCells (state, action: PayloadAction<GridCells>) {
            state.grid.cells = action.payload;
        },
        setSkillQuestsGridCells (state, action: PayloadAction<GridCells>) {
            state.grid.cells = action.payload;
        },
        setSkillQuestsGridCountersInitialValue (state, action: PayloadAction<GridCounters>) {
            state.grid.counters = action.payload;
        },
        toggleSkillQuestsGridCells (state, action: PayloadAction<ToggleGridCell>) {
            const {
                student,
                ...payload
            } = action.payload;
            const {
                gridCells,
                gridCounters
            } = toggleGridCells({
                gridCells: state.grid.cells,
                gridCounters: state.grid.counters,
                studentId: student.userProfileId.toString(),
                ...payload
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
        },
        toggleSkillQuestsStudentGridCheckbox (state, action: PayloadAction<ToggleStudentGridCheckbox>) {
            const {
                student,
                isChecked
            } = action.payload;
            const {
                gridCells,
                gridCounters
            } = toggleStudentGridCheckbox({
                gridCells: state.grid.cells,
                gridCounters: state.grid.counters,
                isChecked,
                studentId: student.userProfileId.toString()
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
        },
        toggleSkillQuestsTopicGridCheckbox (state, action: PayloadAction<ToggleTopicGridCheckbox>) {
            const {
                gridCells,
                gridCounters
            } = toggleTopicGridCheckbox({
                gridCells: state.grid.cells,
                gridCounters: state.grid.counters,
                ...action.payload
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
        },
        toggleSkillQuestsActivityGridCheckbox (state, action: PayloadAction<ToggleActivityGridCheckbox>) {
            const {
                gridCells,
                gridCounters
            } = toggleActivityGridCheckbox({
                gridCells: state.grid.cells,
                gridCounters: state.grid.counters,
                ...action.payload
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
        },
        setSkillQuestsModal (state, action: PayloadAction<RouteHelpersModalConstants | null>) {
            state.modal = action.payload;
        }
    }
});

/**
 * ACTIONS
 */
export const {
    setSkillQuestsCourseIsExpanded,
    setSkillQuestsCourseSelected,
    setSkillQuestsGridCells,
    toggleSkillQuestsStudentGridCheckbox,
    setSkillQuestsGridCountersInitialValue,
    toggleSkillQuestsGridCells,
    toggleSkillQuestsTopicGridCheckbox,
    toggleSkillQuestsActivityGridCheckbox,
    setSkillQuestsModal
} = skillQuestsSlice.actions;

/**
 * SELECTORS
 */
export const selectSkillQuestsCourseSelected = (state: RootState) => state[name].course.selected;

export const selectSkillQuestsGridCounters = (state: RootState) => state[name].grid.counters;

export const selectSkillQuestsGridCells = (state: RootState) => state[name].grid.cells;

export const selectSkillQuestsAssignmentsGrid = (state: RootState) => state[name].assignments.grid;

export const selectSkillQuestsAssignmentsSelected = (state: RootState) => state[name].assignments.selected;

export const selectSkillQuestsModal = (state: RootState) => state[name].modal;

export default skillQuestsSlice.reducer;
