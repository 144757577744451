import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@config/react-redux/store';
import { getQueryParam } from '@config/react-router-dom/helpers';
import { CountryCodes, QueryParams } from '@config/react-router-dom/hooks/query-params.types';
import {
    AppLandingConstants,
    AppLandingModal,
    AppLandingState
} from '@containers/app/app-landing/app-landing.types';

const { name } = AppLandingConstants;
const initialState: AppLandingState = {
    modal: {
        variant: null,
        type: null
    },
    user: {
        preferredCourseCountyCode: null
    }
};

export const appLandingSlice = createSlice({
    name,
    initialState,
    reducers: {
        setAppLandingModal (state, action: PayloadAction<AppLandingModal>) {
            if (state.modal.variant !== action.payload.variant) {
                state.modal = action.payload;
            }
        },
        setAppLandingUserPreferredCountryCode (state, action: PayloadAction<string>) {
            state.user = {
                ...state.user,
                preferredCourseCountyCode: action.payload
            };
        }
    }
});

/**
 * ACTIONS
 */
export const {
    setAppLandingModal,
    setAppLandingUserPreferredCountryCode
} = appLandingSlice.actions;

/**
 * SELECTORS
 */
export const selectAppLandingModal = (state: RootState) => state[name].modal;

export const selectAppLandingPreferredCountryCode = (state: RootState) => {
    return state[name].user.preferredCourseCountyCode ?? getQueryParam(QueryParams.countryCode) ?? CountryCodes.AUS;
};

export default appLandingSlice.reducer;
