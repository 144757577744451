import { DropdownOption } from '@components/dropdown/dropdown.types';
import { CombinedAssignYearGroup } from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

export const transformNewCoursesYearGroups = (
    yearGroups: Array<CombinedAssignYearGroup>,
    validYearGroups: Array<number>
): Array<DropdownOption> => {
    const updatedYearGroups: Array<DropdownOption> = [];

    yearGroups.forEach((item) => {
        if (validYearGroups.includes(item.yearGroup)) {
            updatedYearGroups.push({
                value: item.yearGroup,
                label: item.longName
            });
        }
    });

    /**
        Deprecated feature
    -----------------------------
    updatedYearGroups.push({
        value: 0,
        label: OPTION_DISABLED,
        disabled: true
    });
     */

    return updatedYearGroups;
};
