export enum QueryParams {
    username = 'username',
    password = 'password',
    token = 'token',
    locale = 'locale',
    type = 'type',
    regionId = 'regionId',
    countryCode = 'countryCode',
    classId = 'classId',
    groupId = 'groupId',
    dynamicParams = 'dynamicParams'
}

export enum CountryCodes {
    AUS = 'AUS',
    NZL = 'NZL'
}

export enum DynamicParams {
    conquestResultsEmptyStateNavigation = 'conquestResultsEmptyStateNavigation',
    zoomPromptShown = 'zoomPromptVisible',
	combinedAssignAndReviewDefaultTab = 'combinedAssignAndReviewDefaultTab'
}
