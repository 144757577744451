import React, { PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ScriptingProvider } from '@config/3p-scripting/scripting-provider';
import { ChakraUiProvider } from '@config/chakra-ui/chakra-ui-provider';
import { EnvConfigProvider } from '@config/env/env-config-provider';
import { I18nProvider } from '@config/i18n/i18n-provider';
import { ReactReduxProvider } from '@config/react-redux/react-redux-provider';

export const AppProviders = ({ children }: PropsWithChildren) => {
    return (
        <EnvConfigProvider>
            <ScriptingProvider>
                <ReactReduxProvider>
                    <I18nProvider>
                        <BrowserRouter>
                            <ChakraUiProvider>
                                {children}
                            </ChakraUiProvider>
                        </BrowserRouter>
                    </I18nProvider>
                </ReactReduxProvider>
            </ScriptingProvider>
        </EnvConfigProvider>
    );
};
